class HawkElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' }).innerHTML = `
          <style>
            span {
              color: #00d7d2;
            }
          </style>
          <span><slot></slot></span>
        `;
  }
}

exports.HawkElement = HawkElement;
