/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const { HawkElement } = require('./src/elements/HawkElement');
window.customElements.define('hawk-element', HawkElement);

exports.shouldUpdateScroll = (e) => {
  const blogBasePath = '/blog/';
  const pathname = e.pathname;
  // if we are on /blog/ scroll but if we are on /blog/*/* don't scroll
  if (pathname.startsWith(blogBasePath) && pathname !== blogBasePath) {
    return false
  }
}

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location, prevLocation }, { trackPage = true }) => {
  function trackSegmentPage() {
    if (trackPage) {
      window.analytics?.page({ url: location.href, path: location.pathname });
    }
  }

  // If this is a second page loaded via Gatsby routing, and the delayed loader has
  // not been loaded yet, we want to load it and then track the page.
  if (prevLocation && window.segmentSnippetLoaded === false) {
    window.segmentSnippetLoader(() => {
      trackSegmentPage();
    });
  } else {
    trackSegmentPage();
  }
  if (!Array.isArray(window._hsq)) {
    return;
  }
  const trackPageView = () => {
    const path = window.location ? window.location.pathname + window.location.search + window.location.hash : undefined;
    window._hsq.push(['setPath', path]);
    window._hsq.push(['trackPageView']);
  };

  if (typeof window.requestAnimationFrame === 'function') {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView);
    });
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32);
  }
};
